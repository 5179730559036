import React from 'react';
import { ReactCompareSlider, 
  ReactCompareSliderImage, 
  ReactCompareSliderHandle, 
  styleFitContainer } from 'react-compare-slider';

export default class CompareSlider extends React.Component {

  render() {
    const {
      image1Url,
      image2Url
    } = this.props;
    
    if (!image1Url && !image2Url) {
      return null;
    }
    return (
      <ReactCompareSlider
        handle={
          <ReactCompareSliderHandle 
            buttonStyle={{
              display: 'none'
            }} 
            linesStyle={{
              opacity: 1,
              width: 4,
              borderLeft: '1px solid #000',
              borderRight: '1px solid #000'
            }}
          />
        }
        itemOne={
          <ReactCompareSliderImage 
            src={image1Url} 
            alt="Image one" 
            style={{
            ...styleFitContainer({
                objectFit: 'contain',
                objectPosition: 'top center',
              })
            }}
          />
        }
        itemTwo={
          <ReactCompareSliderImage 
            src={image2Url} 
            alt="Image two" 
            style={{
            ...styleFitContainer({
                objectFit: 'contain',
                objectPosition: 'top center',
              })
            }}
          />
        }
      />
    );
  }
};

