import React from 'react';
import UrlForm from './UrlForm';

export default class PageTopSection extends React.Component {
  
  render() {
    const {
      onScreenshot1Loaded,
      onScreenshot2Loaded
    } = this.props;
    
    return (
      <>
        <p>Screenshots are cached for 24 hours. You only need to force a refresh if changes were made since the last screenshot was taken.
        Differences in the height of the screenshots can make elements seem out of place.</p>
        <div className="row">
          <div className="col-lg-6">
            <UrlForm 
              formName="page-1"
              onScreenshotLoaded={(screenshotUrl) => onScreenshot1Loaded(screenshotUrl)}
              fieldLabel="Page 1"
              fieldPlaceholder="https://example.com/my-first-page/"
              buttonLabel="Load"
              buttonType="submit"
            />
          </div>
          <div className="col-lg-6">
            <UrlForm 
              formName="page-2"
              onScreenshotLoaded={(screenshotUrl) => onScreenshot2Loaded(screenshotUrl)}
              fieldLabel="Page 2"
              fieldPlaceholder="https://example.com/my-second-page/"
              buttonLabel="Load"
              // buttonId="url2"
              buttonType="submit"
            />
          </div>
        </div>
      </>
    );
  }
}
