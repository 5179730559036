import React from 'react';
import Form from 'react-bootstrap/Form';

export default class UrlFormInputLabel extends React.Component {

  render() {
    const {
      label,
      fieldId
    } = this.props;
    
    return (
      <Form.Label 
        htmlFor={ fieldId }
      >
        {label}
      </Form.Label>
    );
  }
};

