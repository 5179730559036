import React from 'react';
import Button from 'react-bootstrap/Button';

export default class UrlFormButton extends React.Component {

  getIsDisabled() {
    return !!this.props.isLoading || this.props.disabled;
  }
  
  getIsLoading() {
    return !!this.props.isLoading;
  }
  
  getButtonText() {
    if (this.getIsLoading()) {
      return 'Loading...';
    }
    return this.props.text;
  }
  
  render() {
    const {
      id,
      type,
    } = this.props;
    
    return (
      <Button 
        variant="outline-primary" 
        id={id} 
        type={ type }
        disabled={ this.getIsDisabled() }
      >
        { this.getButtonText() }
      </Button>
    );
  }
};

