import React from 'react';
import FormControl from 'react-bootstrap/FormControl';

export default class UrlFormInput extends React.Component {

  render() {
    const {
      name,
      id,
      value,
      placeholder,
      onChange,
      defaultValue,
      disabled
    } = this.props;
    
    return (
      <FormControl
        placeholder={placeholder}
        name={name}
        aria-describedby={name}
        onChange={(event) => onChange(event)}
        value={value}
        disabled={disabled}
        defaultValue={defaultValue}
        id={ id }
      />
    );
  }
};

