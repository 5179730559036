import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import UrlFormButton from './UrlFormButton';
import UrlFormInput from './UrlFormInput';
import UrlFormInputLabel from './UrlFormInputLabel';
import UrlFormCheckbox from './UrlFormCheckbox';
import ScreenshotService from '../services/ScreenshotService';
import StorageService from '../services/StorageService';

export default class UrlForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      value: props.fieldDefaultValue || '',
      refresh: false
    };
    this.screenshotService = new ScreenshotService();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setupSubscriptions();
  }
  
  setupSubscriptions() {
    this.screenshotService.imageData$().subscribe(data => {
      if (data.status === "success") {
        this.props.onScreenshotLoaded(data.screenshotImage);
      } else {
        alert('Error Retrieving Screenshot');
      }
      this.setState((state) => {
        return Object.assign(state, {isLoading: false});
      });
    });
  }
  
  handleInputChange(event) {
    this.setState((state) => {
      return Object.assign(state, {value: event.target.value});
    });
  }
  
  handleCheckboxChange(event) {
    this.setState((state) => {
      return Object.assign(state, {refresh: event.target.checked});
    });
  }
  
  canSubmit() {
    if (this.state.isLoading) {
      return false;
    }
    if ((!this.state.value) || (this.state.value.match(/^https?:\/\/[^/]+\.[a-zA-Z]{3,}/gi) === null)) {
      return false;
    }
    return true;
  }
  
  handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    this.setState((state) => {
      return Object.assign(state, {isLoading: true});
    });
    StorageService.setItem(`form-${this.props.formName}`, {
      url: formData.get('url'),
      refresh: !!formData.get('refresh')
    });
    this.screenshotService.getScreenshot(formData);
  }
  
  render() {
    const {
      formName,
      fieldLabel,
      fieldName,
      fieldPlaceholder,
      buttonLabel,
      buttonType
    } = this.props;
    
    const defaults = StorageService.getItem(`form-${this.props.formName}`) || {};

    return (
      <Form onSubmit={(event) => this.handleSubmit(event)}>
        <UrlFormInputLabel
          label={ fieldLabel }
          fieldId={ `${formName}-${fieldName}` }
        />
        <InputGroup>
          <UrlFormInput
            name="url"
            id={ `${formName}-${fieldName}` }
            disabled={ this.state.isLoading }
            defaultValue={ defaults.url }
            placeholder={ fieldPlaceholder }
            onChange={(event) => this.handleInputChange(event) }
          />
          <UrlFormButton
            text={ buttonLabel }
            type={ buttonType }
            disabled={ !this.canSubmit() }
            isLoading={ this.state.isLoading }
          />
        </InputGroup>
        <UrlFormCheckbox
          name='refresh'
          id={`${formName}-refresh`}
          // onChange={(event) => this.handleCheckboxChange(event)}
          disabled={ this.state.isLoading }
          label="Force Refresh"
          // checked={ !!defaults.refresh }
        />
      </Form>
    );
  }
};

