import { Subject } from 'rxjs';


class ScreenshotService {
  imageSubject$ = new Subject();
  apiUrl = 'https://image-compare.pbretailers.com/api/';

  setImage(imageUrl) {
    this.imageSubject$.next({ url: imageUrl });
  }
  
  clearImages() { 
    this.imageSubject$.next(); 
  }
  
  imageData$() {
    return this.imageSubject$.asObservable();
  }
  
  buildQuery(params) {
    const query = new URLSearchParams(params);
    return query.toString();
  }

  getScreenshot(formData) {
    const query = this.buildQuery(formData);
    const apiRequestUrl = `${this.apiUrl}?${query}`;
    fetch(apiRequestUrl)
      .then(response => response.json())
      .then(data => {
        this.imageSubject$.next(data);
      });
  }
};

export default ScreenshotService;