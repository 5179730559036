import React from 'react';
import Form from 'react-bootstrap/Form';

export default class UrlFormCheckbox extends React.Component {

  render() {
    const {
      name,
      id,
      // onChange,
      disabled,
      label,
      // checked
    } = this.props;
    
    return (
      <Form.Check 
        type="switch"
        name={name}
        value="1"
        id={id}
        label={label}
        // checked={checked}
        disabled={disabled}
        // onChange={(event) => onChange(event)}
        className="mb-3"
      />
    );
  }
};

