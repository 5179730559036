
class _StorageService {
  
  storageName = 'image-compare';
  storage = undefined;
  
  getStorage() {
    if (this.storage === undefined) {
      this.storage = JSON.parse(window.localStorage.getItem(this.storageName));
    }
    if (this.storage === null) {
      this.storage = {};
    }
    return this.storage;
  }
  
  setStorage(data) {
    this.storage = data;
    window.localStorage.setItem(this.storageName, JSON.stringify(this.storage));
    return this;
  }
  
  hasItem(name) {
    const storage = this.getStorage();
    return storage[`${name}`] !== undefined;
  }
  
  getItem(name) {
    const storage = this.getStorage();
    return storage[`${name}`] || null;
  }
  
  setItem(name, value) {
    const storage = this.getStorage();
    storage[`${name}`] = value;
    this.storage = storage;
    this.setStorage(this.storage);
    return this;
  }
  
  clearItem(name) {
    const storage = this.getStorage();
    storage[`${name}`] = undefined;
    delete(storage[`${name}`]);
    return this.setStorage(storage);
  }
};

const StorageService = new _StorageService();

// const StorageService = {
  
//   storageName: 'image-compare',
//   storage: undefined,
  
//   getStorage: function() {
//     if (this.storage === undefined) {
//       this.storage = JSON.parse(window.localStorage.getItem(this.storageName));
//     }
//     if (this.storage === null) {
//       this.storage = {};
//     }
//     return this.storage;
//   },
  
//   setStorage: (data) => {
//     this.storage = data;
//     window.localStorage.setItem(this.storageName, JSON.stringify(this.storage));
//     return this;
//   },
  
//   hasItem: (name) => {
//     const storage = this.getStorage();
//     return storage[`${name}`] !== undefined;
//   },
  
//   getItem: (name) => {
//     const storage = this.getStorage();
//     return storage[`${name}`] || null;
//   },
  
//   setItem: (name, value) => {
//     const storage = this.getStorage();
//     storage[`${name}`] = value;
//     this.storage = storage;
//     this.save();
//     return this;
//   },
  
//   clearItem: (name) => {
//     const storage = this.getStorage();
//     storage[`${name}`] = undefined;
//     delete(storage[`${name}`]);
//     return this.setStorage(storage);
//   }
// };

export default StorageService;