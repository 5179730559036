import React from 'react';
import Container from 'react-bootstrap/Container';
import CompareSlider from './components/CompareSlider';
import PageTopSection from './components/PageTopSection';
import './App.scss';

class App extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      screenshot1Url: '',
      screenshot2Url: ''
    };
    // this.screenshot1UrlChange = this.screenshot1UrlChange.bind(this);
    // this.screenshot1Ur2Change = this.screenshot1Ur2Change.bind(this);
  }
  
  screenshot1Change(screenshotUrl) {
    console.log('Screenshot 1 Changed', screenshotUrl);
    this.setState({
      screenshot1Url: screenshotUrl
    });
  }
  
  screenshot2Change(screenshotUrl) {
    console.log('Screenshot 2 Changed', screenshotUrl);
    this.setState({
      screenshot2Url: screenshotUrl
    });
  }
  
  renderScreenshots() {
    if (!!this.state.screenshot1Url) {
      if (!!this.state.screenshot2Url) {
        return (
          <CompareSlider
            image1Url={ this.state.screenshot1Url }
            image2Url={ this.state.screenshot2Url }
          />
        );
      }
      return (
        <img
          src={ this.state.screenshot1Url }
          alt={ 'Screenshot 1' }
          className="d-block w-100"
        />
      );
    } else if (!!this.state.screenshot2Url) {
      return (
        <img
          src={ this.state.screenshot2Url }
          alt={ 'Screenshot 2' }
          className="d-block w-100"
        />
      );
    }
  }

  render() {
    return (
      <Container fluid className="p-3">
        <Container className="p-5 mb-4 bg-light rounded-3">
          <h1 className="header">Webpage Compare</h1>
          <PageTopSection
            onScreenshot1Loaded={(screenshotUrl) => this.screenshot1Change(screenshotUrl)}
            onScreenshot2Loaded={(screenshotUrl) => this.screenshot2Change(screenshotUrl)}
          />
        </Container>
        { this.renderScreenshots() }
      </Container>
    );
  }
}

export default App;
